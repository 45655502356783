<template>
  <div class="layout">
  
    <div class="loading centered" v-if="get_window.loading">
      <Loader />
    </div>

    <router-view class="page" v-if="!get_window.loading"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import Loader from "../components/Loader.vue";

export default {
  name: "Layout",
  components: {
    Loader
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['get_window']),
  },
  method: {
    ...mapActions({get_info:'get_info'})
  }
};
</script>