import 'babel-polyfill' 
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueScrollTo from 'vue-scrollto'
import EasySlider from 'vue-easy-slider'


Vue.use(VueRouter);

window.scrollmem = 0;

import { routes } from './routes.js'
const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach( ( to, from, next ) => {
  store.commit( 'SET_ROUTE_PAGE', { to } );
  next();
});


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBLEFC3Lsuhm4tpqdVwpybgqNK9gq0h9nw',
    libraries: 'places,geometry',
    load: {
      region: 'NL',
      language: 'nl',
    },
  },
})

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.use(EasySlider)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

window.addEventListener('scroll', function(){
  if(window.location.pathname == '/'){
    window.scrollmem = window.scrollY;
  }
});

window.getInfo = function(id){
  window.app.__vue__.$store.dispatch('get_info',id).then(() => {
    window.app.__vue__.$router.push({path: '/info', query: { lid: id }});
  });
}

var doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);