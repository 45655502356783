<template>
  <div class="Aanvragen">
    <link rel="stylesheet" type="text/css" href="/css/aanvragen.css">
    <Aside />

    <div class="page_container">
      <div class="page__wrapper">

<!-- START -->

<div class="card">
  <div v-if="page=='main'" class="aanvraagform" :class="[`step${current_step}`]">
    <div id="aanvraagstep1" @click="setStep(1)" class="aanvraagstep PrimaryBGColor CustomFont">
      Stap 1: Informatie aanvragen<i class="fa fa-angle-down fa-2x" style="float:right;margin-top:-10px"></i>
    </div>
    <div id="aanvraagbox1" class="aanvraagbox">
      <div id="aanvraagbox1content"  class="aanvraagboxcontent">
        <div class="inputbox" style="width:100%;flex:1 1 100%;max-width:100%">
          Filter uitstapjes<br>
          <div class="searchbox">
            <input type="search" id="partnersearch" style="margin-right:20px;width:100%" placeholder="Typ hier de naam van het uitstapje" v-model="search">
          </div>
        </div>
        <div class="top-section">
          <span>Klik aan waar je meer informatie over wilt ontvangen:<br>
          <label class='partnercheck box-info'><img src='/images/aanvragen/info.svg'></label><span class="label-text">info- en/of educatief pakket</span></span><br>en / of<br><span><label class='partnercheck box-tour'><img src='/images/aanvragen/ticket.svg'></label><span class="label-text">vrijkaarten of rondleiding.</span></span>
        </div>
        <div class="partners">
          <div v-for="partner in parks_filtered" class='partnerbox reqbox' :key='`partner-${partner.id}`'>
            <div class='partnername'>{{partner.name}}</div>
            <div class='partnerchecks'>
              <div @click="selectParkInfo(partner.id)" class='partnercheck box-info' :class="{'selected':partner.req_info}"><img src='/images/aanvragen/info.svg'></div>
              <div @click="selectParkTicket(partner.id)" class='partnercheck box-tour' :class="{'selected':partner.req_ticket}"><img src='/images/aanvragen/ticket.svg'></div>
            </div>
          </div>
        </div>
        <div class="warningMessage" v-if="warningMessage">{{warningMessage}}</div>
        <div class="HoverButtonWrap CustomFont">
          <div class="HoverButton SecondaryBGColor" @click="setStep(2)">Naar stap 2</div>
        </div>
      </div>
    </div>
    <div id="aanvraagstep2" @click="setStep(2)" class="aanvraagstep PrimaryBGColor CustomFont">
      Stap 2: Je gegevens invullen<i class="fa fa-angle-left fa-2x" style="float:right;margin-top:-10px"></i>
    </div>
    <div id="aanvraagbox2" class="aanvraagbox">
      <div id="aanvraagbox2content" class="aanvraagboxcontent">
        <div style="width:100%">
          <div style="margin:10px 0">Dit heb je aangevinkt:</div>
          <div class="selectedPartners">
            <div v-for="partner in parks_chosen" class='partnerbox reqbox' :key='`chosen-${partner.id}`'>
              <div class='partnername'>{{partner.name}}</div>
              <div class='partnerchecks'>
                <div class='partnercheck box-info' :class="{'selected':partner.req_info}"><img src='/images/aanvragen/info.svg'></div>
                <div class='partnercheck box-tour' :class="{'selected':partner.req_ticket}"><img src='/images/aanvragen/ticket.svg'></div>
              </div>
            </div>
          </div>
        </div>
        <div style="width:100%"><div style="margin:10px 0;"></div><div style="margin:30px 0 10px 0;">Vul je gegevens in:</div></div>
        <div class="nawFields">
          <div class="inputbox"><div class="inputlabel">NAAM SCHOOL*</div><input class="inputfield" type="text" v-model="form.schoolnaam"></div>
          <div class="inputbox"><div class="inputlabel">VESTIGINGSPLAATS SCHOOL*</div><input class="inputfield" type="text" v-model="form.schoolplaats"></div>
          <div class="inputbox"><div class="inputlabel">E-MAILADRES*</div><input class="inputfield" type="email" v-model="form.email"></div>
          <div class="inputbox"><div class="inputlabel">BEVESTIG E-MAILADRES*</div><input class="inputfield" type="email" v-model="form.emailrep"></div>
          <div class="inputbox"><div class="inputlabel">VOOR- EN ACHTERNAAM*</div><input class="inputfield" type="text" v-model="form.volledigenaam"></div>
          <div class="inputbox"><div class="inputlabel">NIEUWSBRIEF</div><input type="checkbox" v-model="form.nieuwsbrief" id="optin"/><label for="optin" style="font-size:.9em">Schrijf mij in voor de School & Schoolreis nieuwsbrief.</label></div>
        </div>
        <div style="margin-bottom:30px">
          <input type="checkbox" id="akkoord" name="akkoord" v-model="form.concent"/><label for="akkoord">Ik ga akkoord met de <a href="/privacyverklaring.pdf" target="_new" style="text-decoration:underline">privacyverklaring</a>.*</label>
        </div>
        <div class="warningMessage" v-if="warningMessage">{{warningMessage}}</div>
        <div class="HoverButtonWrap"><div class="HoverButton CustomFont SecondaryBGColor" @click="sendForm">
          <img v-if="form.sending" src="/images/aanvragen/button-loader.gif" alt="">
          <div v-else>Aanvraag versturen</div>
        </div></div>
      </div>
    </div>
  </div>
  <div v-if="page=='dank'" class="dank">
    <b>Hartelijk dank voor je aanvraag!</b><br>
    <br>
    We hebben alle informatie bij elkaar gevoegd in een email. Deze email hebben we zojuist aan je gestuurd. Heb je geen mail ontvangen? Kijk dan even in je ’spam’ of ‘ongewenste reclame’.<br>
    <br>
    We wensen jullie alvast veel plezier op jullie volgende schoolreis!<br>
    <br>
    Hartelijke groet,<br>
    Team School en Schoolreis<br>
    <br>
    <div style="width:100%;text-align:center">
      <a href="/" style="text-decoration:none">
        <div class="go__back" style="max-width:320px;font-size:.8em"><img src="/images/icon-chevron-left.svg" alt=""> TERUG NAAR HOOFD PAGINA</div>
      </a>
    </div>
  </div>
  
  </div>

<!-- END -->

      </div>
    </div>

  </div>
</template>

<script>
import Aside from "../components/Aside.vue";
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "Aanvragen",
  components: {
    Aside
  },
  data() {
    return {
      search:'',
      page:'main',
      parks: [],
      parks_filtered: [],
      parks_chosen: [],
      current_step:1,
      warningMessage:'',
      form:{
        schoolnaam:'',
        schoolplaats:'',
        email:'',
        emailrep:'',
        volledigenaam:'',
        nieuwsbrief:false,
        concent:false,
        sending:false,
      }
    };
  },
  computed: {
    ...mapGetters([
      'get_config',
      'get_parks',
    ]),
  },
  mounted(){
    window.scrollTo(0, 0);
    this.get_aanvragen_data().then((res)=>{
      this.parks = res.map(el=>{
        return {id: el.id, name: el.name, req_info: false, req_ticket: false}
      });
      let query = this.$route.query.lid;
      if(query){
        this.search = this.parks.find(el=>el.id == query).name;
      }
      this.update_parks_filtered();
    });
  },
  watch:{
    search(){
      this.update_parks_filtered();
    },
    'form.schoolnaam'(){
      this.warningMessage = '';
    },
    'form.schoolplaats'(){
      this.warningMessage = '';
    },
    'form.email'(){
      this.warningMessage = '';
    },
    'form.emailrep'(){
      this.warningMessage = '';
    },
    'form.volledigenaam'(){
      this.warningMessage = '';
    },
    'form.concent'(){
      this.warningMessage = '';
    },
  },
  methods: {
    ...mapActions({
      get_aanvragen_data: "get_aanvragen_data",
      set_aanvraag: "set_aanvraag",
    }),
    update_parks_filtered(){
      this.parks_filtered = this.parks.filter(el=>el.name.toLowerCase().includes(this.search.toLowerCase()));
    },
    update_parks_chosen(){
      this.parks_chosen = this.parks.filter(el=>el.req_info || el.req_ticket);
    },
    selectParkInfo(id){
      this.warningMessage = '';
      this.parks.find(el=>el.id == id).req_info = !this.parks.find(el=>el.id == id).req_info;
      this.update_parks_filtered();
    },
    selectParkTicket(id){
      this.warningMessage = '';
      this.parks.find(el=>el.id == id).req_ticket = !this.parks.find(el=>el.id == id).req_ticket;
      this.update_parks_filtered();
    },
    setStep(n){
      this.warningMessage = '';
      if(n == 2){
        const selectedInfo = this.parks.find(el=>el.req_info);
        const selectedTickets = this.parks.find(el=>el.req_ticket);
        if(!selectedInfo && !selectedTickets){
          this.warningMessage = 'Kies van welke park je informatie en / of vrijkaarten wilt ontvangen.';
          return;
        }
        this.update_parks_chosen();
      }
      this.current_step = n;
      window.scrollTo({top: Math.round(document.getElementById(`aanvraagstep${n}`).getBoundingClientRect().top), behavior: 'smooth'});
    },
    sendForm(){
      this.warningMessage = '';
      if(this.form.sending){
        return;
      }
      if(this.form.schoolnaam.trim().length == 0){
        this.warningMessage = 'Vul je school naam in.';
        return;
      }
      if(this.form.schoolplaats.trim().length == 0){
        this.warningMessage = 'Vul de plaats van je school in.';
        return;
      }
      var r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!r.test(this.form.email.trim())){
        this.warningMessage = 'Vul je e-mailadres in.';
        return;
      }
      if(this.form.email.trim().length > 0 && this.form.emailrep.trim().toLowerCase() != this.form.email.trim().toLowerCase()){
        this.warningMessage = 'Bevestig e-mailadres komt niet overeen.'
        return;
      }
      const nameParts = this.form.volledigenaam.trim().split(' ');
      if(nameParts.length < 2){
        this.warningMessage = 'Vul je volledige naam in.';
        return;
      }
      if(!this.form.concent){
        this.warningMessage = 'Ga akkoord met de privacyverklaring.';
        return;
      }
      this.form.sending = true;
      let requests = [];
      this.parks_chosen.forEach(element => {
        requests.push([element.id, element.req_info?1:0, element.req_ticket?1:0]);
      });
      this.set_aanvraag({
        cmd: 'requestinfo',
        schoolnaam: this.form.schoolnaam.trim(),
        plaats: this.form.schoolplaats.trim(),
        naam: this.form.volledigenaam.trim(),
        email: this.form.email.trim(),
        optin: this.form.nieuwsbrief,
        req:requests,
      }).then(res =>{
        this.form.sending = false;
        if(res.answer == 'OK'){
          this.page = 'dank';
        }else{
          this.warningMessage = 'Sorry, er is een fout opgetreden. Probeert u het later nog eens.';
        }
      });
    }
  },
};
</script>


