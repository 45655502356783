<template>
  <div ref="locations" :class="['locations', get_window.is_mobile ? 'mobile' : '']">
    
    <Aside ref="aside" v-if="!get_window.is_mobile"/>

    <div class="location__container">
      <div v-if="no_result" class="no-result">
        <div class="shrug">¯\_(ツ)_/¯</div>
        Geen resultaten gevonden voor <b>{{get_options.active_category}}</b>
      </div>
      
      <div ref="locations" class="flexible">

        <template v-if="!get_options.is_map_showing">
          <LocationTile
            v-for="location in get_location_data"
            :key="get_options.location_key + location.id"
            :location="location"
          />
        </template>
        
        <template v-if="get_options.is_map_showing">
          <GmapMap
            ref="gmap"
            :center="center"
            :zoom="8"
            map-type-id="terrain"
            class="map">
            <GmapMarker
              :key="'marker-' + index"
              v-for="(marker, index) in map_markers"
              :position="marker.position"
              :clickable="true"
              :draggable="false"
              :icon="mapMarker(marker.category)"
              @click="showInfoWindow(marker,index)"
               />
            <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen=false">
              <div v-html="infoContent"></div>
            </gmap-info-window>
          </GmapMap>
        </template>
      </div>
      <div style="width:100%;text-align:center">
      <a href="http://www.phantasialand.de" target="_blank" v-if="get_window.is_mobile">
        <img src="images/banners/phantasialand-18092815342241.jpg" alt="">
      </a>
    </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import Aside from "../components/Aside.vue";
import LocationTile from "../components/LocationTile.vue";

export default {
  name: "Location",
  components: {
    Aside,
    LocationTile
  },
  props: {},
  mounted() {
    window.onhashchange = this.handleHashChange;
    this.clear_order();
    this.get_locations().then((res) => {
      if (res == 'success') {
        this.set_loading(false);
        this.handleHashChange();
        window.scrollTo(0,window.scrollmem);
      }
    });
  },
  data() {
    return {
      //a default center for the map
      center: { lat: 52.2507092, lng: 5.3421946 },
      map: null,
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    };
  },
  watch: {
    get_location_data() {
      this.hideInfoWindow();
    }
  },
  computed: {
    ...mapGetters([
      "get_config",
      "get_window",
      "get_options",
      "get_filtered_location_data",
      "get_image_size_multiplier",
    ]),
    no_result(){
      return this.get_location_data.length == 0 && this.get_options.searching;
    },
    get_location_data() {
      let location_data = this.get_filtered_location_data(this.get_options.active_category);
      if(this.get_options.sorting || this.get_options.searching){
        return this.order_special_locations(location_data.filter(el => !el.is_info));
      }
      return this.order_special_locations(location_data);
    },
    map_markers() {
      let markers = [];
      this.get_location_data.forEach(location => {
        //has multiple locations
        var hasMultipleLocations = false;
        if(location.hasOwnProperty('locations')){
          if(location.locations.length > 0){
            hasMultipleLocations = true;
          }
        }
        if(hasMultipleLocations){
          for(let i=0; i<location.locations.length; i++){
            let str = location.locations[i].latlng.split(", ");
            let obj = {
              id: location.id,
              name: location.name,
              description: location.short_description,
              image: location.img,
              position: { lat: Number(str[0]), lng: Number(str[1]) },
              category: location.category,
            };
            markers.push(obj);
          }
        }else{
          let str = location.latlng.split(", ");
          let obj = {
            id: location.id,
            name: location.name,
            description: location.short_description,
            image: location.img,
            position: { lat: Number(str[0]), lng: Number(str[1]) },
            category: location.category,
          };
          if(!location.is_info){
            markers.push(obj);
          }
        }
      });
      return markers;
    },
    aside_top_offset() {
      return this.get_window.aside_y;
    },
  },
  methods: {
    ...mapActions({
      get_info: "get_info",
      clear_order: "clear_order",
      get_locations: 'get_locations',
      set_loading: 'set_loading',
      filter_locations: "filter_locations",
      }),
    order_special_locations(location_data){
      //exclude Neurocampus
      let arr = location_data.filter((el) => el.id != '3716' && el.id != '3715');
      //add Neurocampus
      if(location_data.filter((el) => el.id == '3716').length > 0){
        arr.push(location_data.filter((el) => el.id == '3716')[0]);
      }
      //add Schoolvoordeel
      if(location_data.filter((el) => el.id == '3715').length > 0){
        arr.push(location_data.filter((el) => el.id == '3715')[0]);
      }
      return arr;
    },
    mapMarker(category){
      const c = String(category).trim().toLowerCase();
      let marker = 'default';
      if(c != 'null'){
        marker = c;
      }
      return { url: require('../../public/images/map/'+marker+'.png')};
    },
    handleHashChange(){
      let hash = window.location.hash.substr(1);
      hash = hash.replaceAll('_',' ');
      if(hash){
        this.filter_locations(hash);
      }
    },
    get_order_page(lid) {
      this.get_info(lid).then(() => {
        this.$router.push({ path: "/order", query: { lid: `${lid}` } });
      });
    },
    showInfoWindow(marker, idx) {
      	let w = 251;
		let h = 0;
		let url = 'https://cdn.basisam.nl/images/'+this.get_config.images_cdn+'/'+w+'x'+h+'/'+marker.image;
		let rurl = 'https://cdn.basisam.nl/images/'+this.get_config.images_cdn+'/'+w*2+'x'+h*2+'/'+marker.image;

		let bgimg = `background-image: url('${url}');
			background-image: -webkit-image-set(url('${url}') 1x, url('${rurl}') 2x);
			background-image: image-set(url('${url}') 1x, url('${rurl}') 2x);`;


      this.infoWindowPos = marker.position;
      this.infoContent = `<div class="card">
        <div class="card-image">
          <div class="image" style="${bgimg}width: 100%;height: 100px;background-size: cover;background-position: center;"></div>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <div class="title text-Xlarger text-medium text-block">${
                marker.name
              }</div>
            </div>
          </div>
          <div class="content">
            <div class="text-block">${marker.description}</div>
          </div>
          <br>
          <div onClick="window.getInfo(${marker.id})" class="button meer-info">Meer informatie</div>
        </div>
      </div>`;
      this.infoWinOpen = true;
      this.currentMidx = idx;
    },
    hideInfoWindow() {
      this.infoWinOpen = false;
    },
    get_info_page(lid) {
      this.get_info(lid).then(() => {
        this.$router.push({path: '/info', query: { lid: `${lid}` }});
      });
    }
  }
};
</script>