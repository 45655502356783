import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as geolib from 'geolib'
import Cookies from 'js-cookie'

Vue.use(Vuex, VueAxios, axios)

export default new Vuex.Store({
  strict: true,
  state: {
    config: {
      url:'',
      init:{
        mollie_status:""
      },
      images_cdn: "schoolenschoolreis.nl",
    },
    window: {
      is_mobile: false,
      navigation: false,
      loading: true
    },
    options: {
      location_key: 0,
      active_category: 'All',
      categories: [],
      is_map_showing: false,
      distance: '',
      sorting: false,
      search: '',
      searching: false
    },
    all_location_data: [],
    parks: [],
    selected_location: {},
    order: {
      registration: '',
      aanhef:'',
      firstname: '',
      lastname: '',
      email: '',
      emailrep: '',
      numtickets: '',
      slotid:-1,
      codes:['']
    },
    transaction:{
      download_url : "",
      payment_status : "",
      selected_location : "",
      numtickets : ""
    },
    route_history:[]
  },
  getters: {
    get_image_size_multiplier(){
        // check if retina display
        if (((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
          return 2;
        }
        // check if high density display
        if (((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3))) {
          return 1.3;
        }
        return 1;
    },
    get_config(state) {
      return state.config
    },
    get_window(state) {
      return state.window;
    },
    get_options(state) {
      return state.options;
    },
    get_all_location_data(state) {
      return state.all_location_data;
    },
    get_parks(state){
      return state.parks;
    },
    get_selected_location(state) {
      return state.selected_location;
    },
    get_filtered_location_data: state => category => {
      const filtered_category = category.toLowerCase();
      var valid = state.options.categories.indexOf(filtered_category);
      if (filtered_category === 'all') {
        return state.all_location_data;
      } else if (valid === -1) {
        return state.all_location_data.filter(location => location.name.toLowerCase().includes(filtered_category.toLowerCase()));
      } else {
        return state.all_location_data.filter(location => String(location.category).toLowerCase() == filtered_category);
      }
    },
    get_items_in_category: state => category => {
      return state.all_location_data.filter(location => location.categorie == category).length;
    },
    get_order(state) {
      return state.order;
    },
    get_transaction(state) {
      return state.transaction;
    }
  },
  mutations: {
    //FUNCTIONS
    ADD_TO_OPTIONS(state, obj) {
      state.options[obj.key] = obj.value;
    },
    //CONFIG
    INIT(state, response) {
      state.config.init = response.data;
    },
    SET_ENV(state, url) {
      state.config.url = url;
    },
    //WINDOW
    IS_MOBILE(state, boolean) {
      state.window.is_mobile = boolean;
    },
    SET_LOADING(state, boolean) {
      state.window.loading = boolean;
    },
    SET_ERROR_MSG(state, payload) {
      state.transaction.title = payload.title;
      state.transaction.message = payload.message;
    },
    //ROUTES
    SET_ROUTE_PAGE(state, payload){
      state.route_history.push(payload.to);
    },
    TOGGLE_NAVIGATION(state) {
      state.window.navigation = !state.window.navigation;
    },
    //LOCATION DATA
    SET_LOCATIONS(state, locations) {
      locations.forEach(location => {
        location.distance = 0;
      })
      state.all_location_data = locations;
    },
    SET_PARKS(state, data) {
      state.parks = data;
    },
    SET_INFO(state, response) {
      state.selected_location = response.data.info;
      state.selected_location.tariffs = response.data.tariffs;
      state.selected_location.timeslots = response.data.timeslots;
    },
    SET_CATEGORIES(state, array) {
      state.options.categories = array;
    },
    ADD_DISTANCE(state, payload) {
      let i = state.all_location_data.map(function(location) { return location.id; }).indexOf(payload.lid);
      state.all_location_data[i].distance = payload.distance;
      state.options.location_key++;
    },
    SORT_LOCATIONS(state) {
      state.all_location_data.sort((a, b) => (a.distance > b.distance) ? 1 : -1);
      state.options.sorting = true;
    },
    FILTER_LOCATIONS(state, filter) {
      state.options.active_category = filter.toLowerCase();
      if (!state.options.categories.includes(state.options.active_category)) {
        state.options.searching = true;
      }
      if (state.options.active_category  == 'all') {
        state.options.searching = false;
        window.location.hash = '';
      }else{
        const hash = filter.replaceAll(' ','_');
        window.location.hash = hash;
      }
    },
    TOGGLE_MAP(state, show) {
      state.options.is_map_showing = show;
    },
    RESET_SORTING(state){
      state.options.distance = '';
      state.options.sorting = false;
    },
    RESET_SEARCH(state){
      state.options.search = '';
      state.options.searching = false;
    },
    //PAYMENT
    SET_TICKETNUMS(state, string) {
      state.order.numtickets = string;
    },
    SET_USER_DETAILS(state, payload) {
      state.order.aanhef = payload.aanhef.value;
      state.order.firstname = payload.firstname.value;
      state.order.lastname = payload.lastname.value;
      state.order.email = payload.email.value;
      state.order.emailrep = payload.emailrep.value;
      let tariffid = -1;
      if(state.selected_location.tariffs.length > 0){
        tariffid = state.selected_location.tariffs[0].id;
      }
      state.order.numtickets = tariffid+':'+payload.numtickets;
      state.order.slotid = payload.timeslot.time;
      state.order.codes = [];
      for(var i=0; i<payload.codes.length; i++){
        state.order.codes.push(payload.codes[i].value);
      }
    },
    SET_TRANSACTION(state, response){
      state.transaction = response.data;
    },
    SET_PAYMENT_STATUS(state, response) {
      state.transaction.download_url = response.data.downloadurl;
      state.transaction.payment_status = response.data.payment_status;
      state.transaction.selected_location = response.data.selected_park[0].name;
      state.transaction.numtickets = response.data.numtickets;
    },
    SET_ORDER(state, payload) {
      state.order.tickets = payload.tickets;
      state.order.numtickets = payload.numtickets;
      state.order.parkid = payload.parkid;
      state.order.codes = payload.codes;
    },
    STORE_ORDER(state, order_details) {
      state.order = Object.assign({}, order_details);
    },
    CLEAR_ORDER(state){
      state.order.numtickets = '';
      state.order.slotid = -1;
      state.order.codes = [];
    },
    GO_BACK(state, router){
      if(window.location.hash == '#aanvragen'){
        window.location = '/aanvragen';
        return;
      }
      //remove current page;
      var currPage = state.route_history.pop();
      //remove and prepare previous page;
      var prevPage = state.route_history.pop();
      if(prevPage != undefined && currPage != undefined){
        while(state.route_history.length > 0 && prevPage.meta.skip_history || (currPage.hasOwnProperty('path') && currPage.path == '/info' && prevPage.path == '/info')){
          prevPage = state.route_history.pop();
        }
      }else{
        prevPage = {path: '/', meta:{skip_history:0}}
      }
      router.push(prevPage);
    }
  },
  actions: {
    //CONFIG
    init(context) {
      return new Promise((resolve) => {
        axios.post(context.getters.get_config.url + '/init',{
        }).then((response) => {
          context.commit("INIT", response);
          resolve(response.data);
        }).catch((error) => {
          throw error
        })
      })
    },
    set_env(context, url) {
      context.commit("SET_ENV", url);
    },
    reload_home(context) {
      context.dispatch('clear_mollie');
      return new Promise((resolve) => {
        context.dispatch('get_locations').then((res) => {
          resolve(res);
        })
      });
    },
    clear_mollie(){
      Cookies.remove('PHPSESSID');
    },
    //WINDOW
    is_mobile(context, payload) {
      context.commit("IS_MOBILE", payload);
    },
    set_loading(context, boolean) {
      context.commit('SET_LOADING', boolean);
    },
    set_error_msg(context, payload) {
      context.commit("SET_ERROR_MSG", payload);
    },
    //ROUTES
    toggle_navigation(context) {
      context.commit('TOGGLE_NAVIGATION');
    },
    //LOCATION DATA
    get_locations(context) {
      return new Promise((resolve) => {
        axios.post(context.getters.get_config.url + '/getparks',{
        }).then((response) => {
          context.commit("SET_LOCATIONS", response.data.parks);
          context.dispatch('set_categories');
          resolve('success');
        }).catch((error) => {
          throw error
        })
      })
    },
    get_info(context, lid) {
      return new Promise((resolve) => {
        axios.post(context.getters.get_config.url + '/getparkinfo', {
          lid: lid
        }).then((response) => {
          context.commit("SET_INFO", response);
          resolve('success');
        }).catch((error) => {
          throw error
        })
      })
    },
    get_aanvragen_data(context) {
      return new Promise((resolve) => {
        axios.post('/php/aanvragen/interface.php',{
          cmd: 'getparks'
        }).then((response) => {
          context.commit("SET_PARKS", response.data.parks);
          resolve(response.data.parks);
        }).catch((error) => {
          throw error
        })
      })
    },
    set_aanvraag(context, payload) {
      return new Promise((resolve) => {
        axios.post('/php/aanvragen/interface.php',payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          throw error
        })
      })
    },
    set_categories(context) {
      let a = [];
      this.getters.get_all_location_data.forEach(l => {
        const cat = String(l.category).toLowerCase();
        let valid = a.indexOf(cat);
        if (valid === -1 && cat != 'null') { a.push(cat); }
      });
      context.commit("SET_CATEGORIES", a);
    },
    calc_distances(context, payload) {
      axios.post(context.getters.get_config.url + '/findloc', {
        search: payload
      }).then((response) => {
        let position = response.data.loc;

        for(var i = 0; i < context.getters.get_all_location_data.length; i++){
          let loc = context.getters.get_all_location_data[i];
          var coords = {lat:'',lng:''}
          var dist = 0;
          if(loc.latlng != ""){
            if(loc.latlng != '-'){
              coords = {
                lat: loc.latlng.split(', ')[0],
                lng: loc.latlng.split(', ')[1]
              };
            }else{
              if(loc.hasOwnProperty('locations')){
                //has multiple locations
                if(loc.locations.length > 0){
                  var shortestdistance = 999999;
                  var closest = {};
                  //find closest place
                  for(let il=0; il<loc.locations.length; il++){
                    let distance = geolib.getDistance(position, {lat: loc.locations[il].latlng.split(', ')[0], lng: loc.locations[il].latlng.split(', ')[1]});
                    if(distance < shortestdistance){
                      shortestdistance = distance;
                      closest = {lat: loc.locations[il].latlng.split(', ')[0], lng: loc.locations[il].latlng.split(', ')[1]};
                    }
                  }
                  coords = closest;
                }
              }
            }
            dist = geolib.getDistance(position, coords) / 1000
          }
          context.commit("ADD_DISTANCE", {
            lid: context.getters.get_all_location_data[i].id,
            distance: dist
          })
        }
        context.commit("SORT_LOCATIONS");
      }).catch((error) => {
        throw error;
      })
    },
    sort_locations(context) {
      context.commit('SORT_LOCATIONS');
    },
    filter_locations(context, payload) {
      context.commit("FILTER_LOCATIONS", payload);
    },
    toggle_map(context, show) {
      context.commit("TOGGLE_MAP", show);
    },
    //PAYMENT
    validate_codes(context, payload) {
      return new Promise((resolve) => {
        axios.post(context.getters.get_config.url + '/checkcode',{
          id: payload.parkid,
          code: payload.code,
          field: payload.field
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          throw error
        })
      })
    },
    check_order(context, payload) {
      context.commit("SET_USER_DETAILS", payload);
      let ticket_string = '';
      for (let i = 0; i < payload.desired_tickets.length; i++) {
        if (i < payload.desired_tickets.length - 1) {
          ticket_string += payload.desired_tickets[i][0] + ':' + payload.desired_tickets[i][1] + ',';
        } else {
          ticket_string += payload.desired_tickets[i][0] + ':' + payload.desired_tickets[i][1];
        }
      }
      context.commit("SET_TICKETNUMS", ticket_string);
      let codes = [payload.codes[0].value];
      return new Promise((resolve) => {
        axios.post(context.getters.get_config.url + '/checkorder',{
          aanhef: payload.aanhef.value,
          firstname: payload.firstname.value,
          lastname: payload.lastname.value,
          email: payload.email.value,
          emailrep: payload.emailrep.value,
          numtickets: ticket_string,
          parkid: context.getters.get_selected_location.id,
          codes: codes,
          slotid: 0
        }).then((response) => {
          if (response.data.answer !== 'ERROR' && !response.data.hasOwnProperty('order_error')) {
            context.commit("SET_TRANSACTION", response);
          }
          resolve(response);
        }).catch((error) => {
          throw error
        })
      })
    },
    check_prices(context, payload) {
      let ticket_string = '';

      for (let i = 0; i < payload.tickets.length; i++) {
        if (i < payload.tickets.length - 1) {
          ticket_string += payload.tickets[i][0] + ':' + payload.tickets[i][1] + ',';
        } else {
          ticket_string += payload.tickets[i][0] + ':' + payload.tickets[i][1];
        }
      }
      let codes = payload.code != null ? [payload.code] : [];
      context.dispatch("calculate_prices", {
        tickets: payload.tickets,
        numtickets: ticket_string,
        parkid: payload.parkid,
        email: payload.email,
        codes: codes
      });
    },
    calculate_prices(context, payload) {
      context.dispatch("set_order", {
        tickets: payload.tickets,
        numtickets: payload.numtickets,
        parkid: payload.parkid,
        codes: payload.codes
      })
      axios.post(context.getters.get_config.url + '/checkprices', {
        numtickets: payload.numtickets,
        parkid: payload.parkid,
        codes: payload.codes
      }).then((response) => {
        context.commit("SET_TRANSACTION", response);
      }).catch((error) => {
        throw error
      })
    },
    payment_status(context, mollie_reference) {
      return new Promise((resolve) => {
        axios.post(context.getters.get_config.url + '/checkpay',{
          cmd: "checkpay",
          ref:  mollie_reference
        }).then((response) => {
          context.commit("SET_PAYMENT_STATUS", response);
          resolve(response);
        }).catch((error) => {
          throw error
        })
      })
    },
    place_order(context) {
      return new Promise((resolve) => {
        axios.post(context.getters.get_config.url + '/order',context.state.order).then((response) => {
          context.commit("SET_TRANSACTION", response);
          resolve(response);
        }).catch((error) => {
          throw error
        })
      })
    },
    set_order(context, payload) {
      context.commit("SET_ORDER", payload);
    },
    reset_order(context) {
      context.dispatch('get_locations');
    },
    clear_order(context){
      context.commit("CLEAR_ORDER");
    },
    set_user_details(context, payload) {
      context.commit("SET_USER_DETAILS", payload);
    },
    set_transaction(context, payload){
      context.commit("SET_TRANSACTION",payload);
    },
    go_back(context, router){
      context.commit("GO_BACK",router);
    }
  }
})
