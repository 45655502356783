<template>
  <div class="Error">

    <div class="page_container" style="border:none">
      <div class="page__wrapper text-center">
        <div class="text-Xlarger primary-color">{{ get_transaction.title }}</div>
        <div class="spacer small"></div>
        <div v-html="get_transaction.message"></div>
        <div class="spacer medium"></div>
        <div class="button" @click="reset_app">Opnieuw beginnen</div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default {
  name: "Error",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'get_transaction'
    ])
  },
  methods: {
    ...mapActions({reload_home: 'reload_home'}),
    reset_app(){
      this.reload_home().then((response) => {
        if (response == 'success') { this.$router.push({path: '/'}); }
      })
    }
  }
};
</script>


