<template>
  <div class="Contact footer-page">
    <Aside />

    <div class="page_container">
      <div class="page__wrapper">
        <div class="text-Xlarger secondary-color CustomFont">Heb je vragen? Wij helpen graag!</div>
        <div class="spacer small"></div>
School en Schoolreis is een uitgave van Toeristisch Nederland.<br>
<br>
<b>Meer tips</b><br>
Scholen die meer tips of informatie willen, kunnen hun vraag of vragen mailen naar <a href="mailto:info@schoolenschoolreis.nl?subject=Vraag%20over%20School%20en%20Schoolreis"><u>info@schoolenschoolreis.nl</u></a><br>
<br>
<b>Adverteren</b><br>
Schoolreisbestemmingen die meer willen weten over de mogelijkheden om hun bestemming te presenteren in het magazine of op deze website kunnen contact opnemen via <a href="mailto:partners@activatiemarketing.nl?subject=Vraag%20over%20adverteren%20School%20en%20Schoolreis"><u>partners@activatiemarketing.nl</u></a><br>
<br>
Toeristisch Nederland is een activiteit van <a href="https://www.activatiemarketing.nl" target="_blank"><u>BASIS ActivatieMarketing</u></a>
      </div>
    </div>

  </div>
</template>

<script>
import Aside from "../components/Aside.vue";
import { mapGetters } from 'vuex'

export default {
  name: "Contact",
  components: {
    Aside
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'get_config',
    ]),
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
     set_page(p) {
      this.$router.push({path: p});
    }
  },
};
</script>


